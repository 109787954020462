@font-face {
  font-family: HusansNormal;
  src: url("./assets/fonts/husans-normal-webfont.woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: HusansInline;
  src: url("./assets/fonts/husans-inline-webfont.woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
